import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import HeroLegal from "../components/hero-legal";
import LegalNotice from "../components/legal-notice";

export default function Home({ path, data }) {
  const { entry, seo } = data.cms;
  const { sections, title } = entry;
  return (
    <Layout theme="dark">
      <SEO
        title={seo.title}
        description={seo.og_description}
        meta={[
          {
            "property": "og:image",
            "content": "/images/og-updated.png"
          }
        ]}
      />

      <HeroLegal title={title} />
      <LegalNotice sections={sections} />
    </Layout>
  );
}
export const query = graphql`
  query PageLegal {
    cms {
      entry(id: "ccecb71b-a813-402c-b15a-748c510f524b") {
        ... on cms_Entry_Pages_PageLegal {
          id
          title
          sections {
            content
            section_title
            nav_title
          }
        }
      }
      seo: SeoMeta(page_id: "ccecb71b-a813-402c-b15a-748c510f524b") {
        og_description
        og_title
        title
      }
    }
  }
`;
