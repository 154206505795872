import React, { useState } from "react";
import { withPrefix } from "gatsby";
import styled from "styled-components";
import breakpoints from "../../utils/breakpoints";
import slugify from 'slugify'
import parse from "html-react-parser";

const Container = styled.div`
  position: relative;
  padding: 0 var(--grid-padding) 10rem;
  color: #000;

  @media ${breakpoints.tablet} {
    padding-bottom: 17.5rem;
  }

  h2,
  h3 {
    font-size: 1em;
    line-height: inherit;
    font-weight: 500;
    margin: 0 0 1.5em;
  }

  ul,
  table {
    margin-bottom: 1.5em;
  }

  table {
    border-collapse: collapse;
  }

  td {
    text-align: left;
    font-size: 0.65em;
    vertical-align: top;
    padding: 2rem 1rem;
    border-bottom: 0.1rem solid;
  }

  thead td {
    vertical-align: bottom;
    font-weight: 500;
  }

  td {
    border-right: 0.1rem solid;

    &:last-child {
      border-right: none;
    }
  }

  a {
    color: #3a797e;
  }

  strong {
    font-weight: 500;
  }
`;

const Wrapper = styled.div`
  max-width: 70rem;
  margin: 0 auto;
`;

const NavWrapper = styled.div`
  position: sticky;
  top: 9.8rem;
  background-color: #fffef8;

  @media ${breakpoints.tabletSm} {
    top: 10.2rem;
  }
  @media ${breakpoints.tablet} {
    top: 9rem;
  }
  @media ${breakpoints.desktopSm} {
    position: absolute;
    top: 0;
    left: var(--grid-padding);
    width: calc((100% - 70rem) / 2 - var(--grid-padding));
    padding-right: var(--grid-gutter);
    height: 100%;
  }
`;

const Nav = styled.nav`
  max-width: 70rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  margin: 0 auto 8rem;

  @media ${breakpoints.desktopSm} {
    position: sticky;
    top: 15rem;
    margin-bottom: 0;
    padding-top: 0;
  }

  select {
    display: block;
    width: 100%;
    border: solid;
    border-width: 0 0 0.1rem;
    font-size: 1.6rem;
    line-height: 1.1.375;
    background-color: transparent;
    appearance: none;
    font-weight: 300;
    height: 3.5rem;
    background: transparent url(${withPrefix("caret.svg")}) no-repeat right
      center;
    background-size: 1rem 0.5rem;
    letter-spacing: 0.01em;
    color: #000000;
    border-radius: 0;
    padding: 0;
    outline: none;

    @media ${breakpoints.tabletSm} {
      background-size: 1.3rem 0.6rem;
    }

    @media ${breakpoints.desktopSm} {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.4736842105;

    @media ${breakpoints.desktopSm} {
      display: block;
    }
    @media ${breakpoints.desktop} {
      font-size: 1.9rem;
    }
  }

  a {
    color: #000;
    border-bottom: 0.1rem solid transparent;
    transition: border-bottom-color 0.3s var(--ease-soft);

    &[data-selected="true"] {
      border-bottom-color: #000;
    }
  }
`;

const ScrollTarget = styled.span`
  display: block;
  position: relative;
  top: -18rem;

  @media ${breakpoints.tablet} {
    top: -15rem;
  }
`;

export default function LegalNotice({ sections }) {
  const [selectValue, setSelectValue] = useState(
    typeof window === "undefined" ? "" : window.location.hash
  );

  if (typeof window !== "undefined" && window.location.hash !== selectValue) {
    window.location.hash = selectValue;
  }

  const onSelectChange = e => {
    document.documentElement.style.scrollBehavior = "smooth";

    setSelectValue(e.target.value);

    setTimeout(() => {
      document.documentElement.style.scrollBehavior = "";
    }, 300);
  };

  const onAnchorClick = e => {
    document.documentElement.style.scrollBehavior = "smooth";

    setSelectValue(e.target.hash);

    setTimeout(() => {
      document.documentElement.style.scrollBehavior = "";
    }, 300);
  };

  return (
    <Container>
      <NavWrapper>
        <Nav>
          {/* eslint-disable-next-line */}
          <select onChange={onSelectChange} value={selectValue}>
            {sections.map(section => (
              <option key={slugify(section.section_title)} value={`#${slugify(section.section_title)}`}>
                {section.nav_title}
              </option>
            ))}
          </select>
          <ul>
            {sections.map(section => (
              <li 
                key={slugify(section.section_title)}
                href={`#${slugify(section.section_title)}`}
                onClick={onAnchorClick}
                data-selected={selectValue === `#${slugify(section.section_title)}`}
              >
                <a
                  href={`#${slugify(section.section_title)}`}
                  >
                    {section.nav_title}
                </a>
              </li>
            ))}
          </ul>
        </Nav>
      </NavWrapper>
      <Wrapper>
        {sections.map(section => (
          <section key={slugify(section.section_title)} >
            <ScrollTarget id={slugify(section.section_title)} />
            {parse(section.content || '')}
          </section>
        ))}
      </Wrapper>
    </Container>
  );
}
